import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContainerView from '../views/ContainerView.vue'
import ServiceView from '../views/ServiceView.vue'
import { supabase } from '../clients/supabase'
import emailjs from 'emailjs-com'

let localUser;

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    component: function () {
      return import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    },
    // meta: { requiresAuth: true }
  },
  {
    path: '/container',
    name: 'container',
    component: ContainerView,
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/ContainerView.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/service',
    name: 'service',
    component: function () {
      return import(/* webpackChunkName: "admin" */ '../views/ServiceView.vue')
    },
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

function sendEmail(ip, userAgent, time, screenSize, country, countryCode) {
  emailjs.init('mUIcDTkk26yYMvc6r');
  
  var templateParams = {
    ip: ip,
    userAgent: userAgent,
    time: time,
    screenSize: screenSize,
    country: country,
    countryCode: countryCode
  };
  
  emailjs.send('service_vjde5hm', 'template_8afdhmk', templateParams).then(
    (response) => {
      console.log('SUCCESS!', response.status, response.text);
    },
    (error) => {
      console.log('FAILED...', error);
    }
  );
}

async function getCountry(ip) {
  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`);
    const data = await response.json();
    
    return {
      country: data.country_name,
      countryCode: data.country_code
    };
  } catch (error) {
    console.error('Error fetching country data:', error);
    return { country: null, countryCode: null };
  }
}

async function getUser(next) {
  let localUser = await supabase.auth.getSession();
  console.log(localUser.data.session);
  
  if (localUser.data.session == null) {
    try {
      let ip = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => data.ip);

      let { country, countryCode } = await getCountry(ip);

      alert('Page not found');

      sendEmail(
        ip,
        navigator.userAgent,
        new Date().toLocaleString(),
        window.screen.width + 'x' + window.screen.height,
        country || 'Unknown',
        countryCode || 'Unknown'
      );
    } catch (error) {
      console.error('Error fetching IP or sending email:', error);
    }
  } else {
    next();
  }
}


// auth requirements
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    getUser(next);
  } else {
    next();
  }
});


export default router
