<template>
    <section class="pb-16 sponsorsback bg-[#002039]">
        <h2
        id="committee"
            class="text-white border-t-2 newstitle text-left pl-6 md:pl-12 bg-[#175585] py-2 md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">
            Committee</h2>

        <Swiper class="SponsorsSwiper h-90 w-80 lg:w-96 mt-10" :effect="'cards'" :grabCursor="true" :modules="modules" :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }">

            <swiper-slide v-for="(sponsorsSwiperSlides, index) in sponsormembers" :key="index"
                class="swiperslide bg-white backdrop-blur-md shadow-lg shadow-black py-6 px-6 gap-3 p-2 duration-300 rounded mx-auto">
                <div class="w-20 h-20 rounded mr-auto bg-cover bg-center bg-no-repeat" :id="'swiperslide' + (index + 1)"
                    alt="">
                </div>

                <h2 class="font-semibold mt-3 uppercase">{{ sponsorsSwiperSlides.position }}</h2>
                <p class="text-xs sm:text-xs mt-3">{{ sponsorsSwiperSlides.name }}</p>
                <p class="text-xs sm:text-xs mt-3 overflow-y-scroll h-40">{{ sponsorsSwiperSlides.description }}</p>
            </swiper-slide>

            <div class="swiper-button-next mt-52"></div>
            <div class="swiper-button-prev mt-52"></div>
        </Swiper>
    </section>
</template>

<style lang="scss">
.swiperslide {
    p {
        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ffffff;
            border-radius: 10px;
        }
    }

    &.swiper-slide-active {
        @apply filter-none scale-100 z-10 shadow-black shadow-2xl;
    }

    @for $i from 1 through 7 {
        #swiperslide#{$i} {
            background-image: url("@/assets/committee/#{$i}.jpg");
        }
    }
}
</style>

<script>
export default {
    name: 'SponsorsComp',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            sponsormembers,
            modules
        }
    }
}

// Swiper js
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';

const modules = [EffectCards, Navigation];

const sponsormembers = [
    { position: "Club Chairman", name: "Jeremy Kingston JK", description: "The Chairman is responsible for organising the Club A.G.M. and other Club Meetings; running and co-ordinating these and ensuring that The Club Constitution and Rules are adhered to within these meetings. He/she will assist the Secretary to produce the agendas/keep minutes and head the Committee in making decisions for the benefit of the whole Club including disciplinary matters. He/She should also assist and guide the Committee; liaise with governing bodies, associates and others in order to enhance the running and development of the Club; and advise and deal with Club matters as and when required, taking on specific tasks within the Club structure. He/she will attend all P.Y.L. Meetings taking the opportunity to find out what is going on at League and County level" },
    { position: "Honorary Club President", name: "Marcus Ward", description: "The Honorary President is a non-executive position but should be seen as a “Figure Head” within the Club, to provide advice and guidance for Committee Members and is responsible for assisting the committee in their duties of improving the Club and its facilities" },
    { position: "Vice Chairman", name: "Ryan Zarezadeh", description: "To assist the chairman with his/her role in co-ordinating the committee, to devise and deal with club matters as no when required and also take on specific roles within the club structure" },
    { position: "Club Secretary", name: "Sam Newman", description: "The Club Secretary is a pivotal role within the Club, with a close involvement in the general running of the Club. The Secretary is the official contact between the club, the County FA and the competition organisers and administers all general correspondence for the Club. The Club Secretary carries out or delegates all the administrative duties that enable the Club and its members to function effectively. This includes liaison with the relevant leagues, registration of teams, paying of bills/fines, etc. and with all other Committee and Club members regarding all aspects of administering the club. He/she will attend all P.Y.L. Meetings: taking the opportunity to find out what is going on at League and County level. The Secretary provides the main point of contact for people within and outside the club on just about every aspect of the club’s activities. The Secretary is also responsible for County FA affiliation, League membership and player registrations and transfers. ensuring that only registered players are playing matches for the Club. This may include liaison with the relevant leagues and with all other Committee and club members regarding all aspects of club registration." },
    { position: "Treasurer", name: "Maria Spencer", description: "The Treasurer is responsible for all Club funds and that they are administered legally. Responsibilities also include ensuring that the Committee is aware of the current financial status of the Club and to advise the release or use of funds where necessary. Tasks will include: Collecting subscriptions and all money due to the organisation Paying the bills and recording information – including payments to referees. Keeping up-to -date records of all financial transactions Ensuring that all cash and cheques are promptly deposited in the bank or building society; Ensuring that funds are spent properly Issuing receipts for all money received and recording this information Reporting regularly to the Committee on the financial position Preparing a year end statement of accounts to present to the auditors Presenting a year-end financial report to the AGM Helping to prepare and submit any statutory documents that are required." },
    { position: "Fixture Secretary", name: "Billy Alcock", description: "On a weekly basis during the season, he/she will co-ordinate pitches and kick-off times to all teams and at times of inclement weather determine the suitability of the pitches for matches and training sessions. During the closed season to allocate and co-ordinate times for training sessions on the grass and 3G pitches at Torquay Academy." },
    { position: "Child Welfare Officer", name: "Peter Coleman", description: "The Welfare Officer is responsible for co-ordinating and delivering the Child Protection agenda for the Club. The role holder will be appropriately qualified and checked in line with FA Charter Standard Guidelines and will ensure a rigorous process of checks and screening of all Club managers, trainers and volunteers. The role holder will ensure that all teams operate within guidelines laid down by Charter Standard status and that the welfare and safety of children are of primary concern and without compromise: working with the Club officials particularly the Coaches/Managers to ensure they are carrying out their duty of care when running football activities for children. He/she will be a point of contact for the children if they have any concerns about the way they are being treated. The role involves:" },
]

</script>