<template> 
    <section id="contact" class="contactform bg-white/10 md:mb-10 rounded-lg md:rounded-xl text-white p-3 md:p-7 border-2 border-white/10 max-w-xl mx-4 sm:mx-auto flex items-center justify-center">
        <div class="h-full flex flex-col items-center sm:items-start justify-center">
            <h2 class="text-2xl font-semibold mr-auto">Contact us</h2>
            <p class="opacity-70 mt-2 text-sm mr-auto sm:text-base">Fill out the form below and we will get back to you as soon as possible.</p>
            
            <form action="https://api.web3forms.com/submit" class="mt-10" method="POST">
                <input type="hidden" name="access_key" value="07fcdb43-eca0-421b-856b-edd3962a0845">

                <input name="name" required type="text" class="w-full py-1 pl-2 rounded-md outline-none bg-white/10 text-white" placeholder="Name">
                <input required name="email" type="email" class="w-full py-1 pl-2 mt-2 rounded-md outline-none bg-white/10 text-white" placeholder="Email">
                <textarea required name="message" class="w-full resize-none py-1 pl-2 mt-2 h-20 rounded-md outline-none bg-white/10 text-white" placeholder="Message"></textarea>
                <input type="hidden" name="redirect" value="https://web3forms.com/success">
                <button type="submit" class="hover:bg-white/20 duration-300 p-1 px-10 mt-2 rounded-md bg-white/10 text-white cursor-pointer" value="Submit">Submit</button>
            </form>
        </div>
    </section>
</template>