<template>
  <div id="home" class="overflow-x-clip h-full absolute w-full">
    <header class="w-full h-[40%] sm:h-3/5 bg-[#17558580]">
      <section class="h-full topheader flex justify-center">
        <nav
          class="duration-300 text-white fixed w-full bg-[#17558580] px-4 z-50 font-[roboto] backdrop-blur-md p-2 sm:text-xs text-[10px] flex justify-center h-12 max-h-96 items-center normalnav">
          <a href=""
            class="gap-2 duration-200 mr-auto ml-3 italic text-white h-full flex items-center justify-center text-base font-bold tasheader">
            <img class="w-4" src="../assets/football.png" alt="">
            TAS
          </a>

          <div class="sm:flex h-full ml-auto uppercase text-[12px] hidden md:gap-0">
            <!-- <a v-for="link in navlinks" :href="link.url"
              class="h-full duration-200 flex items-center justify-center px-2 lg:px-4">{{ link.name }}
            </a> -->

            <button @click="ScrollTo('home')"
              class="h-full duration-200 flex items-center justify-center uppercase px-2 lg:px-4">Home</button>
            <button @click="ScrollTo('news')"
              class="h-full duration-200 flex items-center justify-center uppercase px-2 lg:px-4">News</button>
            <button @click="ScrollTo('teams')"
              class="h-full duration-200 flex items-center justify-center uppercase px-2 lg:px-4">Teams</button>
            <button @click="ScrollTo('committee')"
              class="h-full duration-200 flex items-center justify-center uppercase px-2 lg:px-4">Committee</button>
            <button @click="ScrollTo('policies')"
              class="h-full duration-200 flex items-center justify-center uppercase px-2 lg:px-4">Policies</button>
          </div>

          <button @click="ScrollTo('contact')"
            class="sm:flex hidden uppercase duration-200 bg-blue-700 ml-4 hover:bg-blue-600 text-white py-1.5 rounded-[2px] mr-4 -skew-x-12 px-4 lg:px-6">Contact
          </button>

          <input id="toggle" type="checkbox" class="hidden">

          <label @click="expandnav" for="toggle"
            class="hamburger duration-300 absolute top-1.5 right-4 cursor-pointer sm:hidden">
            <div v-for="(classname, index) in ['top-bun', 'meat', 'bottom-bun']" :key="index" :class="classname"></div>
          </label>

          <div class="hiddennav duration-500 -translate-y-10 hidden">
            <!-- <a v-for="(navlinks, index) in navlinks" :key="index" :href="navlinks.url"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">{{
                navlinks.name }}</a> -->

            <button @click="ScrollTo('home')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">Home</button>
            <button @click="ScrollTo('news')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">News</button>
            <button @click="ScrollTo('teams')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">Teams</button>
            <button @click="ScrollTo('committee')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">Committee</button>
            <button @click="ScrollTo('policies')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">Policies</button>
            <button @click="ScrollTo('contact')"
              class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">Contact</button>
          </div>
        </nav>

        <section class="inline-flex flex-col items-start sm:pl-20 pl-8 w-full pt-20 sm:pt-32 backdrop-blur-sm">
          <h1 class="text-white md:text-6xl text-3xl leading-tight font-bold uppercase font-[Roboto] italic">Torquay
            <br>
            <div class="flex items-center justify-center">
              academy Sp<img class="size-7 md:size-16 md:-mr-2 ml-1"
                src="https://img.icons8.com/?size=100&id=xsj5Zlx99Bjv&format=png&color=000000">rt
              F<router-link to="/service" class="cursor-text">C</router-link>
            </div>
          </h1>

          <p class="opacity-90 font-light sm:w-1/2 w-2/3 mt-2 text-xs sm:text-base lg:text-xl text-white italic">Welcome
            to the official Torquay Academy Football site. Check out our club!</p>
          <hr class="w-1/2 mt-4 border-2 rounded-full hidden sm:block">
        </section>
      </section>
    </header>

    <main class="bg-[#002039]">
      <section id="news" class="flex flex-col items-center justify-center border-t-4 gap-2">

        <div class="flex bg-[#175585] py-4 md:px-12 px-6 items-center justify-center w-full">
          <h2 id="latestnewstitle"
            class="newstitle mr-auto md:pl-6 text-white newstitle md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">
            Latest News</h2>
          <button class=" text-white md:text-base text-sm" id="viewallbutton" @click="showallnews">View all</button>
        </div>

        <section
          class="md:py-6 py-2 md:pb-12 grid grid-cols-2 sm:flex flex-wrap max-w-[1000px] px-2 mx-auto items-center justify-center w-full text-white gap-3 md:my-4 newsboxcontainer">
        </section>
      </section>

      <section>
        <div class="flex bg-[#175585] py-4 md:px-12 px-6 items-center justify-center w-full">
          <h2 id="latestnewstitle"
            class="mr-auto md:pl-6 text-white md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">Next
            match</h2>
        </div>

        <div class="flex flex-col text-white items-center justify-center px-10 pt-8 pb-10">
          <div class="flex gap-2 font-semibold text-lg">
            <h4>{{ match1value }}</h4>
            <span class="italic underline">VS</span>
            <h4>{{ match2value }}</h4>
          </div>

          <div>
            <p class="mt-1 text-xs">{{ datevalue }} - {{ locationvalue }}</p>
          </div>
        </div>
      </section>

      <section class="cards_sponsors bg-white md:bg-[#002039]">
        <section id="teams" class="md:pb-20 pb-5 currentteams">
          <h2
            class="text-white border-t-2 newstitle text-left pl-6 md:pl-12 bg-[#175585] py-2 md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">
            Current Teams

            <div class="flex md:hidden items-center absolute top-2 right-2 justify-center">
              <div class="flex gap-1 text-white text-xs rounded">
                <button @click="pastteams = false" :class="{ 'bg-black/40': !pastteams }"
                  class="w-1/2 rounded-sm px-2 pr-5 duration-200 py-0.5">Current</button>

                <button @click="pastteams = true" :class="{ 'bg-black/40': pastteams }"
                  class="w-1/2 rounded-sm px-2 pr-2 duration-200 py-0.5">Past</button>
              </div>
            </div>
          </h2>

          <div class="hidden md:flex w-full items-center py-4 md:my-10 justify-center">
            <div class="flex gap-1 bg-white/20 text-white px-1 w-56 py-1 rounded">
              <button @click="pastteams = false" :class="{ 'bg-black/40': !pastteams }"
                class="w-1/2 rounded py-1 px-4 duration-200">Current</button>

              <button @click="pastteams = true" :class="{ 'bg-black/40': pastteams }"
                class="w-1/2 rounded py-1 px-4 duration-200">Past</button>
            </div>
          </div>

          <div class="md:bg-white rounded-3xl md:w-2/3 max-w-[530px] mx-auto mt-1.5 md:mt-0">
            <Swiper :grabCursor="true" :centeredSlides="true" :initialSlide="0" :slidesPerView="1"
              :direction="'vertical'" :pagination="{ el: '.swiper-pagination', clickable: true }" :effect="'fade'"
              :fadeEffect="{ crossFade: true }" :modules="modules" pagination="true" :class="{ 'h-[250px]': pastteams }"
              class="teams-swiper h-[430px] md:h-60 font-[Fira Sans] overflow-visible" @slideChange="onSlideChange"
              @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
              :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }">

              <swiper-slide v-for="(team, index) in teams" :key="index"
                class="teamsswiper flex flex-col md:flex-row md:pb-4 overflow-visible items-center justify-start md:justify-center md:p-4 md:gap-10">
                <img class="rounded" v-if="!pastteams" :src="team.image" />
                <img class="!w-auto !mx-auto rounded !sm:h-full mt-3 md:h-auto md:mt-0" v-if="pastteams"
                  :src="team.image2" />

                <div v-if="!pastteams" class="teamcontent">
                  <p>Manager: {{ team.manager }}</p>
                  <h4>{{ team.teamName }}</h4>
                  <p>{{ team.description }}</p>
                </div>
              </swiper-slide>

              <div class="swiper-pagination hidden sm:block w-4 p-1 rounded-full md:mr-2 md:mt-0"></div>

                <div class="swiper-button-prev w-5 h-5 md:ml-2 mt-48 sm:mt-36 text-black sm:text-white"></div>
                <div class="swiper-button-next w-5 h-5 md:mr-2 mt-48 sm:mt-36 text-black sm:text-white"></div>
            </Swiper>
          </div>
        </section>
        <SponsorsComp />
      </section>

      <!-- <section class="flex flex-col pb-4 sm:pb-16 items-center justify-center md:mt-10 mt-6">
  <h2 class="text-white newstitle text-left pl-12 bg-black text-2xl font-bold scale-y-125 tracking-tight italic uppercase">committee</h2>
  
  <section class="flex sm:p-10 scale-75 sm:w-screen sm:scale-100 committemembers relative overflow-hidden">
    <div class="scroll-container flex gap-6">
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John #</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
    </div>
  </section>
</section> -->

      <section class="pb-4 mt-10 sm:pb-16">
        <section id="policies" class="w-11/12 max-w-[1000px] mx-auto flex flex-col gap-2 text-white justify-center">
          <h2 class="text-2xl sm:ml-7 ml-2 font-bold scale-y-125 tracking-tight italic uppercase">Policies</h2>
          <p class="sm:ml-7 ml-2 text-orange-300 mt-0">Applicable on: <span id="date"></span></p>

          <hr class="mt-4 opacity-70">
          <div class="w-full px-2 sm:p-2 rounded flex items-center policyboxes text-white flex-col">
            <div class="w-full sm:px-4 flex items-center">
              <h2 class="sm:text-xl font-semibold tracking-tight italic uppercase">Spectators and Parents/Carers</h2>

              <button @click="ExpandPolicy($event.currentTarget)"
                class="plusminus ml-auto scale-75 sm:scale-100"></button>
            </div>

            <div
              class="text-[10px] h-0 duration-300 overflow-y-hidden text-xs md:text-sm sm:gap-10 gap-4 font-light md:flex-row flex-col px-4 md:px-10 mr-auto policytext">
              <ul class="list-disc">
                <li>Have fun; it's what we're all here for!</li>
                <li>Celebrate effort and good play from both sides</li>
                <li>Always respect the Referee and coaches and encourage players to do the same</li>
                <li>Stay behind the touchline and within the Designated Spectators' Area (where provided)</li>
                <li>When players make mistakes, offer them encouragement to try again next time</li>
                <li>Never engage in, or tolerate offensive, insulting or abusive language or behaviour</li>
              </ul>

              <div>
                <p class="font-semibold -ml-4 mt-4 mb-2">I understand that if I do not follow the Code, I may be:</p>
                <ul class="list-disc">
                  <li>Issued with a verbal warning or asked to leave</li>
                  <li>Required to meet with the club committee, league or CFA Welfare Officer</li>
                  <li>Requested not to attend future games, be suspended or have my membership removed</li>
                  <li>Required to leave the club along with any dependants and/or issued a fine</li>
                </ul>
              </div>
            </div>
          </div>

          <hr class="opacity-70">
          <div class="w-full px-2 sm:p-2 rounded flex items-center policyboxes text-white flex-col">
            <div class="w-full sm:px-4 flex items-center">
              <h2 class="sm:text-xl font-semibold tracking-tight italic uppercase">Young Players</h2>

              <button @click="ExpandPolicy($event.currentTarget)"
                class="plusminus ml-auto scale-75 sm:scale-100"></button>
            </div>

            <div
              class="text-[10px] h-0 duration-300 overflow-y-hidden text-xs md:text-sm sm:gap-10 gap-4 font-light md:flex-row flex-col px-4 md:px-10 mr-auto policytext">
              <ul class="list-disc">
                <li>Always play my best for the benefit of the team</li>
                <li>Play fairly and be friendly</li>
                <li>Play by the rules and respect the Referee</li>
                <li>Shake hands with the other team - win or lose</li>
                <li>Listen carefully to what my coach tells me</li>
                <li>Understand that a coach has to do what's best for the team</li>
                <li>Talk to someone I trust or the club welfare officer if I'm unhappy about anything at my club</li>
                <li>Encourage my team mates</li>
                <li>Respect the facilities home & away</li>
              </ul>

              <div>
                <p class="font-semibold -ml-4 mt-4 mb-2">I understand that if I do not follow the Code, I may be:</p>
                <ul class="list-disc">
                  <li>Asked to apologise to whoever I've upset</li>
                  <li>Receive a formal warning</li>
                  <li>Be dropped, substituted or suspended from training</li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="opacity-70">
        </section>
      </section>

      <section class="py-10" id="contact">
        <ContactForm />
      </section>
    </main>

    <footer class="bg-[#175585] text-white text-center p-8">
      <section class="grid grid-cols-2 gap-4 sm:grid-cols-4 font-thin text-xs md:text-sm max-w-4xl mx-auto">
        <button class="underline">Phone: <a href="tel:+44 7709 640872">+44 7709 640872</a></button>
        <p>Copyright <span>{{ copyrightyear }}</span> © TasFC</p>
        <a class="underline" href="mailto:tasfc50@gmail.com">Email: tasfc50@gmail.com</a>
        <p>Icons by <a class="underline" href="https://icons8.com" target="_blank">Icons8</a></p>
      </section>
      <!-- <h2 class="scale-y-110">Torquay Academy FC © 2024 - {{ footerdate }} - Created with ♱ by <a href="https://jacksweb.co.uk" target="_blank" class="text-green-600 underline">JacksWeb</a></h2> -->
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

// Supabase
import { supabase } from '../clients/supabase';

// Swiper js
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import { EffectCards, EffectFade, Pagination, Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';

// Components
import ContactForm from '@/components/ContactForm.vue';
import SponsorsComp from '@/components/Sponsors.vue';

const modules = [EffectCards, EffectFade, Pagination, Navigation, Autoplay, EffectCoverflow];
const match1value = ref("");
const match2value = ref("");
const datevalue = ref("");
const locationvalue = ref("");
const news_headline = ref("");
const copyrightyear = getYear();
const pastteams = ref(false);
const currentteams = ref(true);

const navlinks = [
  { name: "Home", url: "#" },
  { name: "News", url: "#news" },
  { name: "Committee", url: "#committee" },
  { name: "Teams", url: "#teams" },
  { name: "Policies", url: "#policies" },
]

const teams = [
  {
    manager: "Craig Duff",
    teamName: "U8's",
    description: "This is our U8's team. Managed by Craig Duff with assisted coaching from Tom Bearne.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/1.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team1.jpeg?raw=true'
  },
  {
    manager: "Jamie Munro",
    teamName: "U8 Trojans",
    description: "This is our U8 Trojans team. Managed by Jamie Munro with assisted coaching from Paul Shepard and Mike Carrasco.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/2.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team2.jpeg?raw=true'
  },
  {
    manager: "Barry Munro",
    teamName: "U9 Predators",
    description: "This is our U9 Predators team. Managed by Barry Munro with assisted coaching from David Tucker.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/3.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team3.jpeg?raw=true'
  },
  {
    manager: "Alan Gowland",
    teamName: "U10 Colts",
    description: "This is our U10 Colts team. Managed by Alan Gowland with assisted coaching from Phil Lerendu.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/4.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team4.jpeg?raw=true'
  },
  {
    manager: "Karl Williams",
    teamName: "U10 Trojans",
    description: "This is our U10 Trojans team. Managed by Karl Williams with assisted coaching from David Grove and Pete Coleman.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/5.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team5.jpeg?raw=true'
  },
  {
    manager: "James Wenman",
    teamName: "U11 Blues",
    description: "This is our U11 Blues team. Managed by James Wenman with assisted coaching from Sam Stephans.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/6.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team6.jpeg?raw=true'
  },
  {
    manager: "Lee Davis",
    teamName: "U12's",
    description: "This is our U12's team. Managed by Lee Davis with assisted coaching from Adam Newman.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/7.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team7.jpeg?raw=true'
  },
  {
    manager: "Dan Butterworth",
    teamName: "U12 Colts",
    description: "This is our U12 Colts team. Managed by Dan Butterworth with assisted coaching from Steven Courtier, Adam Robertson, and Paul Wood.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/8.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team8.jpeg?raw=true'
  },
  {
    manager: "Luke Asplen",
    teamName: "U14's",
    description: "This is our U14's team. Managed by Luke Asplen with assisted coaching from Derek Fields and Neville Wrathall.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/9.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team9.jpeg?raw=true'
  },
  {
    manager: "Des Pitman",
    teamName: "U14 Predators",
    description: "This is our U14 Predators team. Managed by Des Pitman with assisted coaching from Scott Somerset and Chris Watts.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/10.jpg?raw=true',
    image2: 'https://github.com/tasfc/news-images/blob/main/old-teams/team10.jpeg?raw=true'
  },
  {
    manager: "John English",
    teamName: "U15 Tornados",
    description: "This is our U15 Tornadoes team. Managed by John English with assisted coaching from Phil Lerendu and Cavan Kirby.",
    image: 'https://github.com/tasfc/news-images/blob/main/teams/11.jpg?raw=true',
  },
];

function ScrollTo(id) {
  const newsboxcontainer = document.querySelector('#' + id);
  newsboxcontainer.scrollIntoView({ behavior: 'smooth' });
}

function nextslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slideNext();
}
function prevslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slidePrev();
}

function getYear() {
  return new Date().getFullYear();
}

function getCurrentDate() {
  const currentDateElement = document.getElementById('date');

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  currentDateElement.textContent = `${day}/${month}/${year}`;
}

function HideExpandedNews() {
  const expandednews = document.querySelector('.expandednews');

  expandednews.style.opacity = "0";
}

async function get_news_db() {
  const newsboxcontainer = document.querySelector('.newsboxcontainer');

  let { data: news_table, error } = await supabase
    .from('news_table')
    .select('*');

  if (error) {
    console.error('Error fetching data:', error.message);
    return;
  }

  news_table.forEach((news, index) => {
    const { news_date, news_heading, news_para, image_url } = news;

    if (!news_heading || news_heading.trim() === "null") {
      return;
    }

    const newnews = document.createElement('div');
    newnews.classList.add('news_boxes');
    newnews.innerHTML = `
      <img src="${image_url}" class="w-full h-20 sm:h-40 object-cover sm:rounded-t newsimages newimage${index}" data-index="${index}" />

      <p class="text-xs mt-4 mb-2 opacity-80">${news_date}</p>
      <h2 class="sm:mb-3 text-sm sm:text-base font-semibold newsheading">${news_heading}</h2>

      <p class="newsdesc text-xs sm:text-sm mt-5 max-h-20 pb-2 opacity-70 overflow-y-scroll">${news_para}</p>
    `;

    newnews.querySelector('.newsimages').addEventListener('click', expandnews);
    newsboxcontainer.appendChild(newnews);

    const seemorebutton = document.querySelector('#viewallbutton');

    // if there are 3 or less news, disable the 'View All' button
    if (newsboxcontainer.querySelectorAll('.news_boxes').length <= 3) {
      seemorebutton.style.display = "none";
    } else {
      seemorebutton.style.display = "block";
    }
  });
}


function expandnews(event) {
  const newsElement = event.currentTarget;
  const newsBox = newsElement.closest('.news_boxes');
  // const newsDesc = newsBox.querySelector('.newsdesc');

  // newsDesc.style.display === "block" ? newsDesc.style.display = "none" : newsDesc.style.display = "block";
}

async function get_DB_Values() {
  get_news_db()

  const { data, error } = await supabase
    .from('tas_database')
    .select('*')
    .eq('id', 22);

  if (error) {
    console.error('Error fetching data:', error);
    return;
  }

  const rowData = data[0];

  if (!rowData) {
    console.error('No data found for id 22');
    return;
  }

  match1value.value = rowData.match_1 || '';
  match2value.value = rowData.match_2 || '';
  news_headline.value = rowData.news || '';
  locationvalue.value = rowData.location || '';
  datevalue.value = rowData.date || '';
}

function expandnav() {
  const [nav, hiddennav] = [document.querySelector(".normalnav"), document.querySelector(".hiddennav")];
  const isExpanded = nav.style.height == "40%";

  nav.style.height = isExpanded ? "48px" : "40%";
  hiddennav.style.display = isExpanded ? "none" : "block";
  hiddennav.style.transform = isExpanded ? "translateY(-40px)" : "translateY(0)";

  if (!isExpanded) {
    hiddennav.style.opacity = "0";
    setTimeout(() => hiddennav.style.opacity = "1", 150);
  }
}

function showallnews() {
  const newsbox4 = document.querySelector('.news_boxes:nth-child(4)');
  const newsbox5 = document.querySelector('.news_boxes:nth-child(5)');
  const viewallbutton = document.querySelector('#viewallbutton');

  // Toggle the display of the next two news boxes
  if (viewallbutton.textContent === 'View all') {
    if (newsbox4) {
      newsbox4.style.display = "block";
    }
    if (newsbox5) {
      newsbox5.style.display = "block";
    }


    viewallbutton.textContent = "View less";
  } else {
    if (newsbox4) {
      newsbox4.style.display = "none";
    }
    if (newsbox5) {
      newsbox5.style.display = "none";
    }

    viewallbutton.textContent = "View all";
  }
}


function ExpandPolicy(currentTarget) {
  const policybox = currentTarget.closest('.policyboxes');
  const plusminus = policybox.querySelector('.plusminus');
  const policytext = policybox.querySelector('.policytext');
  const isActive = policybox.classList.contains('active');

  policybox.classList.toggle('active');
  plusminus.classList.toggle('active');

  policytext.style.height = isActive ? '0' : `${policytext.scrollHeight}px`;
  policytext.style.marginTop = isActive ? '0' : '15px';
}

onMounted(() => {
  get_DB_Values();

  const header = document.querySelector('.tasheader');
  const nav = document.querySelector('.normalnav');
  const hamburger = document.querySelector('.hamburger');

  window.onscroll = () => {
    const isScrolled = window.scrollY > 2;

    // nav.style.height = isScrolled ? '38px' : '48px';
    // header.style.fontSize = isScrolled ? '13px' : '18px';
    // hamburger.classList.toggle('hamburgerscrolled', isScrolled);
  };

  getCurrentDate();
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

html {
  scroll-behavior: smooth;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    transition: all 0.3s;
  }

  .swiper-pagination-bullet-active {
    height: 20px;
    border-radius: 100px;
  }
}

.readmore-button {
  background: linear-gradient(to right, #4ab1f1, #074081);
}

.footballimage {
  animation: ease-in-out 5s infinite footballAnimation;
}

#teamsrightsection {
  background-image: url('https://images.unsplash.com/photo-1624880357913-a8539238245b?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D.jpg');
  background-size: cover;
  background-position: center;
}

.committemembers:hover .scroll-container {
  animation-play-state: paused;
}

.commmembers:hover {
  scale: 1.05;
}

.plusminus {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }

    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 0;
    transition: .35s;
    width: 100%;
    height: 3px;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }

}

.scroll-container {
  animation: marquee 15s linear infinite;

  div {
    @apply bg-cover bg-center bg-no-repeat;
  }
}

.pastteams-container {
  .swiperslide {
    @apply size-40 scale-90 sm:size-52 rounded duration-300 opacity-50;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.swiper-slide-active {
      @apply scale-100 opacity-100;
    }
  }
}


:global(.swiper-pagination-bullet) {
  @apply bg-white;
}

.swiper-button-prev,
.swiper-button-next {
  @apply text-white;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  @apply text-3xl;
}

.topheader {
  background-image: url('https://images.unsplash.com/photo-1517927033932-b3d18e61fb3a?q=80&w=1338&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');

  @apply bg-cover bg-center bg-no-repeat;
}

.hamburger {
  @apply mt-[5px];

  div {
    @apply relative w-6 h-[2px] rounded-[3px] bg-white mt-[5px] duration-300;
  }
}

.hamburgerscrolled {
  @apply scale-[0.8];
  top: 2px !important;
  right: 5px !important;
}

#toggle:checked+.hamburger .top-bun {
  @apply mt-[14px] -rotate-45;
}

#toggle:checked+.hamburger .bottom-bun {
  @apply rotate-45 opacity-0;
}

#toggle:checked+.hamburger .meat {
  @apply -mt-[2px] rotate-45;
}

#toggle:checked+.hamburger+.nav {
  @apply scale-100 top-0;
}

.team-match {
  @apply -skew-x-[10deg];
}

.news_boxes {
  @apply p-2 sm:w-[250px] duration-200 bg-white/10 rounded;
  font-family: "Signika Negative", sans-serif;

  .newsimages {
    @apply bg-cover bg-center bg-no-repeat;
  }

  p {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.455);
      border-radius: 50px;
    }

  }
}

.news_boxes:nth-child(4) {
  display: none;
}

.news_boxes:nth-child(5) {
  display: none;
}

.policyboxes {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: black;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.455);
    border-radius: 50px;
  }
}

.teamsswiper {

  &.swiper-slide-active {

    div,
    img {
      opacity: 1;
      transform: none;
      transition-delay: .3s;
    }
  }

  img {
    @apply w-full h-2/3 md:size-52 md:w-[150%] md:-ml-32 border-b-4 md:border-0 border-black md:rounded-xl md:shadow-black/70 shadow-xl;
  }

  div {
    @apply flex flex-col px-6 pr-10 md:px-0 h-1/3 md:h-auto text-xs font-semibold gap-2 ml-2 md:py-10 md:pr-20 text-left md:ml-0 justify-center md:items-start md:justify-normal;

    p:nth-child(1) {
      @apply opacity-70;
    }

    h4 {
      @apply text-lg text-left font-bold;
    }

    p:nth-child(3) {
      @apply font-normal;
    }
  }


  div,
  img {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s, transform 0.3s;
  }
}

@keyframes footballAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}
</style>